import { TypeProps } from '../../types';
import { WithOnClose, WithOpened, WithTitle } from '../../withs';
import useTypeTranslation from '../../hooks/useTypeTranslation';
import Modal2 from '../../molecules/Modal2';
import Avatar from '../../molecules/Avatar';
import { formatAmount } from '@gotombola/amount';
import useStyling from '@gotombola/react-modules-engine/lib/hooks/useStyling';
import clsx from 'clsx';
import AnimatedImage from '../../atoms/AnimatedImage';
import { useMemo } from 'react';
import Text from '../../atoms/Text';

export function TypeImageModal({
    buttonLabel,
    buttonTarget,
    doc,
    item,
    onClose,
    opened = false,
    sponsorings,
    title,
    type,
}: TypeImageModalProps) {
    const { t } = useTypeTranslation(type, doc);
    const { mediaShapeClass, blockShapeClass } = useStyling();
    const sponsoringsDescriptions = !!sponsorings?.length && sponsorings?.filter((x) => !!x?.description);
    const rankString =
        item && item.rank && item.quantity
            ? `${item.rank}${item.quantity > 1 ? `-${item.rank + (item.quantity - 1)}` : ''}`
            : undefined;

    const extraImages = useMemo(
        () =>
            [
                item?.image1Image,
                item?.image2Image,
                item?.image3Image,
                item?.image4Image,
                item?.image5Image,
                item?.image6Image,
                item?.image7Image,
                item?.image8Image,
                item?.image9Image,
            ].filter((x: any) => !!x?.url) as any[],
        [
            item?.image1Image,
            item?.image2Image,
            item?.image3Image,
            item?.image4Image,
            item?.image5Image,
            item?.image6Image,
            item?.image7Image,
            item?.image8Image,
            item?.image9Image,
        ],
    );

    return (
        <Modal2
            title={title}
            opened={opened}
            onClose={onClose}
            buttonTarget={buttonTarget}
            buttonLabel={buttonLabel}
            overline={rankString ? t(`${type}_listing_rank_position_item`, { rankString }) : undefined}
        >
            <div className={'flex-1 md:flex'}>
                <div className={'md:flex-1 sm:aspect-auto items-center justify-center m-4 flex'}>
                    {!!item && !!item.mainImage && (
                        <AnimatedImage
                            className={clsx('w-full object-cover box-border', mediaShapeClass)}
                            mainImage={item.mainImage}
                            maxSize={1024}
                            alt={item.mainImage?.alt || 'game'}
                            extraImages={extraImages}
                            speed={3}
                        />
                    )}
                </div>
                <div className={'md:flex-1 border-t sm:border-l sm:border-t-0 space-y-4 py-4 px-6'}>
                    <div className={'mt-4 space-y-4'}>
                        {!!item && !!item.name && <div className={'font-go-text text-xl'}>{item && item.name}</div>}
                        {!!item && !!item.priceAmount && (
                            <div className={'font-go-text text-md'}>
                                {formatAmount(item.priceAmount, item.priceCurrency)}
                                <span className={'ml-2 font-light text-sm text-gray-500'}>
                                    {t(`${type}_listing_estimated_value_item`)}
                                </span>
                            </div>
                        )}
                        {!!sponsorings?.length &&
                            sponsorings.map(({ name, websiteUrl, mainImage, logoImage }, i) => (
                                <Avatar
                                    key={i}
                                    name={name}
                                    websiteUrl={websiteUrl}
                                    logoImage={
                                        (logoImage?.url ? logoImage : undefined) ||
                                        (mainImage?.url ? mainImage : undefined)
                                    }
                                />
                            ))}
                        {!!item && !!item.description && (
                            <div className={'text-gray-500 font-light text-sm sm:text-md'}>
                                <div className={'font-go-text mb-2 text-go-secondary'}>{t(`${type}_about_item`)}</div>
                                {item && <Text>{item.description}</Text>}
                            </div>
                        )}
                        {!!item && !!item.longDescription && (
                            <div className={'text-gray-500 font-light text-sm sm:text-md'}>
                                <div className={'font-go-text mb-2 text-go-secondary'}>{t(`${type}_more_details`)}</div>
                                {item && <Text>{item.longDescription}</Text>}
                            </div>
                        )}
                        {item && !!item.tag && (
                            <div
                                className={clsx(
                                    'font-go-text w-fit p-1.5 bg-black text-go-panel-secondary font-bold uppercase mb-4',
                                    blockShapeClass,
                                )}
                            >
                                {item && item.tag}
                            </div>
                        )}
                        {!!sponsoringsDescriptions && !!sponsoringsDescriptions?.length && (
                            <div className={'text-gray-500 font-light text-sm sm:text-md mb-4'}>
                                {sponsoringsDescriptions?.map(({ description, name }, i) => (
                                    <div className={'mb-2'} key={i}>
                                        <div className={'font-go-text mb-2 text-go-secondary'}>
                                            {t(`${type}_sponsoring_about_sponsor`, { name })}
                                        </div>
                                        <Text>{description}</Text>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal2>
    );
}

export interface TypeImageModalProps extends TypeProps, Required<WithOnClose>, WithOpened, WithTitle {
    buttonLabel?: string;
    buttonTarget?: string;
    item?: {
        name: string;
        description?: string;
        longDescription?: string;
        tag?: string;
        priceAmount?: number;
        priceCurrency?: string;
        quantity?: number;
        rank?: number;
        mainImage?: { url: string; alt?: string };
        image1Image?: { url: string; alt?: string };
        image2Image?: { url: string; alt?: string };
        image3Image?: { url: string; alt?: string };
        image4Image?: { url: string; alt?: string };
        image5Image?: { url: string; alt?: string };
        image6Image?: { url: string; alt?: string };
        image7Image?: { url: string; alt?: string };
        image8Image?: { url: string; alt?: string };
        image9Image?: { url: string; alt?: string };
    };
    sponsorings: {
        name?: string;
        description?: string;
        websiteUrl?: string;
        logoImage?: { url: string; alt: string };
        mainImage?: { url: string; alt: string };
    }[];
}

export default TypeImageModal;
